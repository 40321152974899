
import { ref } from 'vue';
import moment from 'moment';
import CardTable from '@/components/kt-datatable/CardTable.vue';

export default {
  name: 'CustomerLists',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Customer Account', ['Customer Account Lists']];

    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'User',
        key: 'profileImage',
        sortable: true,
      },
      {
        key: 'name',
        sortable: false,
      },
      {
        name: 'Date Created',
        key: 'createdAt',
        sortable: true,
      },
      {
        name: 'Verification Details',
        key: 'details',
        sortable: false,
      },
    ];

    const loopers = ['name'];
    const tableRef = ref<any>(null);

    return {
      tableHeader,
      breadcrumbs,
      loopers,
      moment,
      tableRef,
    };
  },
};
